<template>
  <v-container id="zonas" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-map-marker-radius"
      inline
      :title="$t('zones')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" color="primary" elevation="2" @click.stop="dialog = true"
          >Nueva Zona</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular :size="50" color="success" indeterminate></v-progress-circular>
      </v-overlay>

      <!-- Barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />
      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Zona -->
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Zona -->
                <v-col cols="3">
                  <v-text-field
                    dense
                    label="Zona*"
                    maxlength="25"
                    required
                    v-model="editedItem.zona"
                    :error-messages="zonaErrors"
                    @blur="$v.editedItem.zona.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.zona.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Municipio -->
                <v-col cols="3">
                  <v-text-field
                    dense
                    label="Municipio*"
                    maxlength="25"
                    required
                    v-model="$v.editedItem.municipio.$model"
                    :error-messages="municipioErrors"
                    @blur="$v.editedItem.municipio.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.municipio.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Estado -->
                <v-col cols="3">
                  <v-text-field
                    dense
                    label="Estado*"
                    required
                    v-model="$v.editedItem.estado.$model"
                    :error-messages="estadoErrors"
                    @blur="$v.editedItem.estado.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.estado.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- País -->
                <v-col cols="3">
                  <v-text-field
                    dense
                    label="Pais*"
                    required
                    v-model="$v.editedItem.pais.$model"
                    :error-messages="paisErrors"
                    @blur="$v.editedItem.pais.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.pais.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Descripción -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Descripción*"
                    required
                    v-model="editedItem.descripcion"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.descripcion.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Kilometros -->
                <v-col cols="12">
                  <v-slider
                    dense
                    label="Kilometros*"
                    required
                    max="500"
                    min="0"
                    step="5"
                    thumb-color="error"
                    thumb-label="always"
                    ticks
                    v-model="editedItem.kilometros"
                    :error-messages="kilometrosErrors"
                    @blur="$v.editedItem.kilometros.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.kilometros.$reset()"
                  ></v-slider>
                </v-col>

                <!-- Tiempo Pickup -->
                <!-- <v-col cols="12">
                  <v-slider
                    dense
                    label="Pick Up*"
                    required
                    max="5"
                    min="1"
                    step=".5"
                    thumb-color="error"
                    thumb-label="always"
                    ticks
                    v-model="editedItem.pickup"
                    :error-messages="pickupErrors"
                    @blur="$v.editedItem.pickup.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.pickup.$reset()"
                  ></v-slider>
                </v-col> -->

                <!-- Pickups por aeropuerto -->
                <v-col cols="12">
                  <Pickups :pickups="editedItem.pickups" @update:pickups="updatePickups"></Pickups>
                </v-col>

                <!-- Tipo Zona -->
                <v-col cols="6">
                  <!-- <v-switch dense label="Zona" v-model="editedItem.tipo"></v-switch> -->
                  <ToggleSwitch :options="myOptions" v-model="editedItem.tipo" />
                </v-col>

                <!-- Boletos Ferry -->
                <v-col cols="6">
                  <v-switch dense label="Boletos Ferry" v-model="editedItem.boletos"></v-switch>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de tipo de Zonas  -->
      <v-data-table
        dense
        item-key="_id"
        loading-text="Cargando... Espere por favor"
        show-expand
        x-small
        :expanded.sync="expanded"
        :headers="headers"
        :items="zonas"
        :loading="isLoading"
        :single-expand="singleExpand"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton boletos-->
        <template v-slot:[`item.boletos`]="{ item }">
          <Status
            :activo="item.boletos"
            txtAct="Sí"
            txtIna="No"
            colAct="primary"
            colIna="blue-grey lighten-3"
          />
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <!-- Slot no data -->
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡Agregue tiempos de pickup!
        </template>

        <!-- Expanded item -->
        <template v-slot:[`expanded-item`]="{ headers, item }">
          <td
            :colspan="headers.length / 2"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>HORARIOS DE PICKUP:</h5>
            <div v-for="(pickup, index) in item.pickups" :key="index">
              <b>Apto: </b> {{ pickup.aeropuerto }} - <b>Pickup: </b>
              <i>{{ pickup.pickup }} horas </i>
            </div>
          </td>

          <!-- <b>Importe:</b> <i>{{ item.pago.importe }}</i> <br />
            <b>Moneda:</b> <i>{{ item.pago.moneda }}</i> <br />
            <b>Tipo:</b> <i>{{ item.pago.tipo }}</i> <br />
            <b>Referencia:</b> <i>{{ item.pago.referencia }}</i> <br /> -->

          <!-- <td
            :colspan="headers.length / 4"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>NOTA:</h5>
            <i>{{ item.zona }}</i
            ><br />
          </td> -->
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="zonas" :dataFields="fields" fileName="Zonas" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Pickups from '@/components/general/Pickups.vue'
import Status from '@/components/general/StatusInfo.vue'
import ToggleSwitch from '@/components/general/ToggleSwitch.vue'

export default {
  name: 'Zonas',
  components: {
    ConfirmationDialog,
    DataExporter,
    Pickups,
    Status,
    ToggleSwitch,
  },

  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    singleExpand: true,
    aeropuertos: [],
    expanded: [],
    headers: [
      { text: 'Tipo', align: 'start', value: 'tipo', width: '7%' },
      { text: 'Zona', align: 'start', sortable: false, value: 'zona' },
      { text: 'Municipio', value: 'municipio' },
      { text: 'Estado', value: 'estado' },
      { text: 'Pais', value: 'pais' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Kms', value: 'kilometros', align: 'right', width: '7%' },
      { text: 'Boletos', align: 'center', value: 'boletos', width: '10%' },
      { text: 'Activo', align: 'center', value: 'activo', width: '10%' },
      { text: 'Acciones', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    users: [],
    value: [],
    zonas: [],
    defaultItem: {
      _id: '',
      tipo: '',
      zona: '',
      municipio: '',
      estado: '',
      pais: '',
      descripcion: '',
      kilometros: 0,
      pickup: null,
      pickups: [],
      boletos: false,
      activo: true,
    },
    editedItem: {
      _id: '',
      tipo: '',
      zona: '',
      municipio: '',
      estado: '',
      pais: '',
      descripcion: '',
      kilometros: 0,
      pickup: null,
      pickups: [],
      boletos: false,
      activo: true,
    },
    fields: {
      Zona: 'zona',
      Municipio: 'municipio',
      Estado: 'estado',
      Pais: 'pais',
      Descripción: 'descripcion',
      Boletos: 'boletos',
    },
    myOptions: {
      layout: {
        color: 'gray',
        backgroundColor: 'lightgray',
        selectedColor: 'white',
        selectedBackgroundColor: 'primary',
        borderColor: 'gray',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: false,
      },
      size: {
        fontSize: 0.8,
        height: 3,
        padding: 0.5,
        width: 10,
      },
      config: {
        delay: 0.4,
        preSelected: 'unknow',
        disabled: false,
        items: [
          { name: 'Zona A', value: 'A', color: 'white', backgroundColor: 'red' },
          { name: 'Zona B', value: 'B', color: 'white', backgroundColor: 'green' },
        ],
      },
    },
  }),

  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      zona: { required, minLength: minLength(3), maxLength: maxLength(25) },
      municipio: { required, minLength: minLength(3), maxLength: maxLength(25) },
      estado: { required, minLength: minLength(3), maxLength: maxLength(20) },
      pais: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      kilometros: { required, minLength: minLength(1), maxLength: maxLength(3) },
      // pickup: { required, minLength: minLength(1), maxLength: maxLength(4) },
    },
  },

  computed: {
    // Propiedad computada para errores en campo descripción
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    kilometrosErrors() {
      const errors = []
      if (!this.$v.editedItem.kilometros.$dirty) return errors
      !this.$v.editedItem.kilometros.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.kilometros.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracter.')
      !this.$v.editedItem.kilometros.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    pickupErrors() {
      const errors = []
      if (!this.$v.editedItem.pickup.$dirty) return errors
      !this.$v.editedItem.pickup.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.pickup.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracter.')
      !this.$v.editedItem.pickup.maxLength &&
        errors.push('Este campo debe de tener un máximo de 4 caracteres.')
      return errors
    },

    estadoErrors() {
      const errors = []
      if (!this.$v.editedItem.estado.$dirty) return errors
      !this.$v.editedItem.estado.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.estado.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.estado.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    municipioErrors() {
      const errors = []
      if (!this.$v.editedItem.municipio.$dirty) return errors
      !this.$v.editedItem.municipio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.municipio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.municipio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Zona' : 'Editar Zona'
    },

    paisErrors() {
      const errors = []
      if (!this.$v.editedItem.pais.$dirty) return errors
      !this.$v.editedItem.pais.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.pais.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.pais.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo zona
    zonaErrors() {
      const errors = []
      if (!this.$v.editedItem.zona.$dirty) return errors
      !this.$v.editedItem.zona.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.zona.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.zona.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    /*     socket.on('updated zone', (id) => {
      console.info(`Actualizada zona ${id}`)
    })

    socket.on('users', (users) => {
      console.info(`Entra en socket users`)
      users.forEach((user) => {
        user.self = user.userID === socket.id
        console.info(`User.self: ${user.userID}`)
        // initReactiveProperties(user)
      })
      // put the current user first, and sort by username
      this.users = users.sort((a, b) => {
        if (a.self) return -1
        if (b.self) return 1
        if (a.username < b.username) return -1
        return a.username > b.username ? 1 : 0
      })
      console.info(this.users)
    }) */

    this.obtenerZonas()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('zone/deleteZone', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de zonas
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * paises
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de zonas
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.zonas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.zonas.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar zonas
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.zonas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log(this.editedItem)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas las zonas
     */
    async obtenerZonas() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('zone/fetchZones', payload)
        .then((response) => {
          this.zonas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
      // }
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para una zona nueva o
     * la edicion de una zona
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          tipo: this.editedItem.tipo,
          zona: this.editedItem.zona,
          municipio: this.editedItem.municipio,
          estado: this.editedItem.estado,
          pais: this.editedItem.pais,
          descripcion: this.editedItem.descripcion,
          kilometros: this.editedItem.kilometros,
          pickup: 0,
          pickups: this.editedItem.pickups,
          boletos: this.editedItem.boletos,
        }
        // console.log(body)
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('zone/editZone', payload)
            .then(() => {
              /*  socket.emit('updated zone', {
                id: 25,
              }) */
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.zonas[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('zone/addZone', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.zonas.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },

    updatePickups(newPickups) {
      this.editedItem.pickups = newPickups
    },
  },
}
</script>
