<template>
  <v-container>
    <!-- Botón para añadir un nuevo pickup -->
    <v-btn class="mb-2 mt-0" @click="openDialog" color="primary" x-small>Agregar Pickup</v-btn>

    <!-- Tabla de Vuetify para mostrar los pickups -->
    <v-data-table :headers="headers" :items="localPickups" class="elevation-1" dense>
      <template v-slot:[`item.aeropuerto`]="{ item }">
        <span :style="{ fontSize: '11px' }" class="font-weight-normal text-body">
          {{ item.aeropuerto }}
        </span>
      </template>

      <template v-slot:[`item.pickup`]="{ item }">
        <span :style="{ fontSize: '11px' }" class="font-weight-normal text-body">
          {{ item.pickup }}
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editPickup(item)">mdi-pencil</v-icon>
        <v-icon small @click="removePickup(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- Dialogo para agregar pickup por aeropuerto -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title> Añadir Pickup </v-card-title>

        <v-card-text>
          <v-col cols="12">
            <v-select
              dense
              label="Aeropuerto*"
              item-text="aeropuerto"
              item-value="iata"
              persistent-hint
              required
              v-model="$v.editedItem.aeropuerto.$model"
              :error-messages="aeropuertoErrors"
              :items="aeropuertos"
              :menu-props="{ top: true, offsetY: true }"
              @blur="$v.editedItem.aeropuerto.$touch()"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-slider
              dense
              label="Pick Up*"
              required
              max="10"
              min="1"
              step=".5"
              thumb-color="error"
              thumb-label="always"
              ticks
              v-model="$v.editedItem.pickup.$model"
              :error-messages="pickupErrors"
              @blur="$v.editedItem.pickup.$touch()"
              @input="$v.editedItem.pickup.$reset()"
            ></v-slider>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'Pickups',
  /**
   * @description Define las props que recibe el componente
   * @property {Array} pickups - Array de pickups (tiempos de recogida y aeropuertos)
   */
  props: { pickups: Array },

  /**
   * @description Datos del componente
   * @returns {Object} Retorna los datos utilizados en el componente
   */
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      defaultItem: {
        aeropuerto: '',
        pickup: 0,
      },
      editedItem: {
        aeropuerto: '',
        pickup: 0,
      },
      aeropuertos: [],
      // Headers para las columnas de la tabla
      headers: [
        { text: 'Aeropuerto', align: 'start', sortable: false, value: 'aeropuerto' },
        { text: 'Pick Up', value: 'pickup', align: 'right', sortable: false },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      // Datos de ejemplo para la tabla
      localPickups: [],
    }
  },

  mixins: [validationMixin],

  /**
   * @description Validaciones para los campos del formulario
   */
  validations: {
    editedItem: {
      aeropuerto: { required },
      pickup: { required },
    },
  },

  /**
   * @description Watcher para reaccionar a cambios en las props de pickups
   */
  watch: {
    pickups: {
      immediate: true,
      handler(newVal) {
        console.log(newVal)
        this.localPickups = JSON.parse(JSON.stringify(newVal))
      },
    },
  },

  /**
   * @description Hook creado para obtener aeropuertos al inicializar el componente
   */
  created() {
    this.obtenerAeropuertos()
  },

  computed: {
    aeropuertoErrors() {
      const errors = []
      if (!this.$v.editedItem.aeropuerto.$dirty) return errors
      !this.$v.editedItem.aeropuerto.required && errors.push('Este campo es requerido')
      return errors
    },

    pickupErrors() {
      const errors = []
      if (!this.$v.editedItem.pickup.$dirty) return errors
      !this.$v.editedItem.pickup.required && errors.push('Este campo es requerido')
      return errors
    },
  },

  methods: {
    /**
     * @description Cierra el diálogo y reinicia los valores editados
     */
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Obtiene los aeropuertos
     * @return {array} arreglo con todos los aeropuertos
     */
    obtenerAeropuertos() {
      let payload = {}
      this.$store
        .dispatch('airports/fetchAirports', payload)
        .then((response) => {
          this.aeropuertos = response.data.map((airport) => ({
            _id: airport._id,
            aeropuerto: airport.aeropuerto,
            iata: airport.iata,
          }))
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Abre el diálogo para agregar o editar un pickup
     */
    openDialog() {
      this.dialog = true
    },

    /**
     * @description Edita un pickup existente
     * @param {Object} item - El objeto pickup a editar
     */
    editPickup(item) {
      this.editedIndex = this.localPickups.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Elimina un pickup
     * @param {Object} item - El objeto pickup a eliminar
     */
    removePickup(item) {
      const index = this.localPickups.indexOf(item)
      if (index > -1) {
        this.localPickups.splice(index, 1)
        this.updateParent()
      }
    },

    /**
     * @description Guarda un nuevo pickup o actualiza uno existente
     */
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          Object.assign(this.localPickups[this.editedIndex], this.editedItem)
          this.updateParent()
        } else {
          const aeropuertoExistente = this.localPickups.some(
            (pickup) => pickup.aeropuerto === this.editedItem.aeropuerto
          )

          if (!aeropuertoExistente) {
            this.localPickups.push(this.editedItem)
            this.updateParent()
          } else {
            this.$store.commit('ALERT', {
              color: 'error',
              text: 'Ya existe tiempo asignado para este aeropuerto',
            })
          }
        }

        this.close()
      }
    },

    /**
     * @description Emite un evento para actualizar los pickups en el componente padre
     */
    updateParent() {
      this.$emit('update:pickups', this.localPickups)
    },
  },
}
</script>
